window.addEventListener('securitypolicyviolation', (e) => {
  let srcElementString
  try {
    srcElementString = (e.srcElement as any)?.outerHTML || (e.target as any)?.outerHTML || 'unknown'
  } catch (e) {
    srcElementString = e.message
  }

  let documentURI = e.documentURI
  if (documentURI?.includes('signup_token')) {
    documentURI = documentURI.substring(0, 100) + '...(redacted)'
  }

  const report = {
    // So we can find the page it was triggered on:
    hash: window.location.hash,
    // So we can filter these in the logs:
    d4hManualReport: true,
    // Somehow we have to pick these manually
    // for them to get sent:
    report: {
      blockedURI: e.blockedURI,
      columnNumber: e.columnNumber,
      disposition: e.disposition,
      documentURI: documentURI,
      effectiveDirective: e.effectiveDirective,
      lineNumber: e.lineNumber,
      originalPolicy: e.originalPolicy,
      sample: e.sample,
      srcElement: srcElementString,
      sourceFile: e.sourceFile,
      type: e.type,
      violatedDirective: e.violatedDirective
    }
  }

  console.error('CSP violation', report)

  window.fetch('/csp-report', {
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(report)
  }).catch((e) => {
    console.error('Failed to send CSP violation report', e)
  })
})
